import StateMachine from 'javascript-state-machine';

export class InteractivityController {
    constructor(config, delegate) {
        this.initialized = false;
        this.config = config;
        this.delegate = delegate;

        this.init();
    }

    init() {
        const { settings: { initialState }, states } = this.config;
        
        // create states
        const transitions = [];

        // loop each state
        Object.keys(states).forEach(key => {
            const state = states[key];

            // add all triggers as transitions to state machine
            state.triggers.forEach(trigger => {
                transitions.push({
                    name: trigger.to,
                    from: key,
                    to: trigger.to
                });
            });
        });

        // create state machine
        this.machine = new StateMachine({
            init: initialState,
            transitions,
            methods: {
                onBeforeTransition: this.selectNextVideo.bind(this),
                onLeaveState: this.blendOver.bind(this),
                onTransition: this.swapContent.bind(this),
                //onAfterTransition: 
            }
        });

        this.initialized = true;
        console.log(this);
    }

    async selectNextVideo({ from, to }) {
        if(from === 'none') {
            // this is initial state
            await this.delegate.setState(to, this.config.states[to]);
            return;
        }
        
        console.log("Preloading next video");
        const trigger = this.config.states[from].triggers.find(t => t.to === to);
        if (!trigger) {
            console.error("No trigger present", from, to);
            return;
        }
        await this.delegate.preloadTransitionVideo(to, this.config.states[to], trigger);
    }

    async blendOver({ from, to }) {
        if(from === 'none') {
            return;
        }
        const operations = [ this.delegate.blendActions(false) ];

        const state = this.config.states[from];
        if(state.blends && state.blends.out) {
            console.log("Blending out image", from, to, state.blends);
            operations.push( this.delegate.blendImage(state.blends.out.image) );
        }
        await Promise.all(operations);
    }

    async blendIn({ from, to }) {
        if (from === 'none') {
            return;
        }
        const operations = [ this.delegate.blendActions(true) ];

        const state = this.config.states[to];
        if (state.blends && state.blends.in) {
            console.log("Blending in image", from, to, state.blends);
            operations.push( this.delegate.blendImage(state.blends.in.image, 1, 0) );
        }

        await Promise.all(operations);
    }

    async swapContent({from, to}) {
        if(from === 'none') {
            return;
        }

        const state = this.config.states[to];
        console.log("Swapping content", from, to, state);
        const nextBGRef = this.delegate.setState(to, state);
        await this.delegate.transitionVideo(to, state);
        await this.blendIn({ from, to });
        await nextBGRef;
        this.delegate.updateState(to);
    }

    destroy() {
        // TODO do something here?
    }
}