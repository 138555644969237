import React, { useState, useEffect, useRef } from 'react';

import ReactMarkdown from 'react-markdown';
import Lottie from 'react-lottie';

import { getWindowDimensions, clamp } from '../utils';

const styles = {
    wrapper: {
        zIndex: 1000,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: `rgba(0, 0, 0, 0.3) 6px 6px 10px`,
        transition: 'opacity 0.5s ease-in-out'
    },
    content: { 
        flex: 1,
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        marginRight: '-50px',
        paddingRight: '50px',
        paddingBottom: '40px'
    },
    arrow: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        width: '14px',
        height: '14px',
        position: 'absolute',
        left: '-6px',
    }
}

const ContentPopup = ({ config: { settings }, isOpen = true, icons, popup, onClose, useRealSize }) => {
     // state
    const [ opened, setOpen ] = useState(isOpen);
    const [ contentData, setContentData ] = useState(null);
    const [ hasScroll, setHasScroll ] = useState(false);

    // refs
    const contentRef = useRef();
    const scrollRef = useRef();

    const getViewSize = () => {
        return useRealSize ? getWindowDimensions(settings).real : getWindowDimensions(settings)
    };

    const sizeRef = useRef({
        ...getViewSize()
    });

    const onWindowResize = () => {
        sizeRef.current = {
            ...getViewSize()
        }
    };

    useEffect(() => {
        setHasScroll(false);
        setContentData(null);
        setOpen(isOpen);
        
        (async () => {
            if(!popup) {
                return;
            }
            const content = await fetch(`${settings.base}/${popup.content.name}.md`).then(res => res.text());
            setContentData(content);

            // check if content scrolls
            setTimeout(() => {
                if(!contentRef.current) {
                    return;
                }
                const { clientHeight, scrollHeight } = contentRef.current;
                setHasScroll( clientHeight < scrollHeight );
            }, 50);
        })();

        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        }

    }, [isOpen, settings.base, popup]);

    if(!popup) {
        //return <div></div>;
    }

    const { padding = 4, border = 2, default: defaults } = settings.popup;
    const { width, height } = sizeRef.current;

    const { 
        pos = defaults.pos, 
        width: maxWidth = defaults.width, 
        height: maxHeight = defaults.height 
    } = popup ? popup.content : {};

    const left = pos.x * width;
    const top = pos.y * height;

    const absoluteMaxWidth = clamp(width * clamp(maxWidth, 0.01, 0.99), 100, width-(left * 2)-50);
    return <>
        <div style={{
                ...styles.wrapper, 
                left: `${left}px`,
                top: `${top}px`,
                maxWidth: `${absoluteMaxWidth}px`,
                padding: `${padding}px`,
                opacity: opened ? 1 : 0,
                pointerEvents: opened ? 'auto' : 'none'
            }}>
            <div style={{
                border: `#5197b1 dotted ${border}px`,
                padding: `${padding}px`
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div>
                        {
                            popup && icons && icons[popup.content.icon] && 
                            <Lottie 
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: icons[popup.content.icon].data,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                width={120}
                                height={120}
                            />
                        }
                        
                    </div>
                    <div style={{flex: 1, display: 'flex', alignItems: 'flex-end'}}>
                        {
                            popup && <h1>{popup.content.title}</h1>
                        }
                    </div>
                    { popup && !popup.hideClose &&
                        <div onClick={() => {
                            setOpen(false);
                            if(typeof onClose === 'function') {
                                setTimeout(() => {
                                    onClose();
                                }, 500);
                            }
                        }} style={{
                            backgroundImage: `url(${settings.base}/icons/exit.svg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            width: '25px',
                            height: '25px',
                            cursor: 'pointer'
                        }}>

                        </div>
                    }

                </div>
                <div style={{ display: 'flex', position: 'relative' }}>
                    <div 
                        ref={contentRef} 
                        style={{...styles.content, maxHeight: `${clamp(height * maxHeight, 100, height - top - 300)}px`}} 
                        onScroll={() => {
                            if(scrollRef.current) {
                                const { scrollHeight, scrollTop, clientHeight } = contentRef.current;
                                const pos = scrollTop / (scrollHeight - clientHeight);
                                scrollRef.current.style.top = `${(clientHeight-100)*clamp(pos, 0, 1)}px`;
                            }
                        }}>
                        {
                            contentData  ?
                            <ReactMarkdown 
                                source={contentData}
                            />
                            : <div>Lade...</div>
                        }
                    </div>
                    {
                        hasScroll &&
                        <div style={{position: 'absolute', width: '100%', height: '20px', background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'}}>
                        </div>
                    }
                    {
                        hasScroll &&
                        <div style={{position: 'absolute', bottom: 0, width: '100%', height: '40px', background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'}}>
                        </div>
                    }
                    {
                        hasScroll && 
                        <div style={{ 
                            borderRight: '#5197b1 dotted 2px',
                            marginLeft: 5,
                            marginTop: 30,
                            marginBottom: 20,
                            overflow: 'visible',
                            position: 'relative'
                         }}>
                            <div style={{
                                ...styles.arrow, 
                                backgroundImage: `url(${settings.base}/icons/arrow.svg)`,
                                top: '-14px',
                                }}>
                            </div>
                            <div ref={scrollRef}  style={{
                                position: 'absolute',
                                width: '10px',
                                height: '40px',
                                top: 0,
                                left: '-4px',
                                backgroundColor: '#5197b1',
                                opacity: 0.2
                            }}>
                            </div>
                            <div style={{
                                ...styles.arrow, 
                                backgroundImage: `url(${settings.base}/icons/arrow.svg)`,
                                transform: 'rotate(180deg)',
                                bottom: '-6px',
                                }}>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>;
}

export default ContentPopup;