import React, { useState, useEffect } from 'react';

import { ErrorDisplay } from './components/error-display';
import { Renderer } from './renderer';

import { getConfig } from './utils';

document.addEventListener('gesturestart', (e) => {
  e.preventDefault();
});

const App = ({configPath}) => {
  // state
  const [rendererConfig, setRendererConfig] = useState();
  const [error, setError] = useState();

  // load config
  useEffect(() => {
    if (!configPath) {
      setError({
        code: 'no_config',
        message: 'Keine Konfiguration gefunden'
      });
      return;
    }

    getConfig(configPath).then(config => {
      setRendererConfig(config);
    }).catch(err => {
      setError({
        code: 'invalid_config',
        message: 'Konfiguration konnte nicht geladen werden',
        context: {
          configPath,
          originalError: err
        }
      })
    });
  }, [configPath]);

  if (error) {
    return (
      <ErrorDisplay error={error} />
    )
  }

  if (!rendererConfig) {
    return (
      <div>
        ...loading // TODO make me pretty :)
      </div>
    )
  }

  const isDebug = window.location.search.indexOf('debug=true') !== -1;

  return (
    <Renderer config={rendererConfig} debug={isDebug}/>
  );
}

export default App;
