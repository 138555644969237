
import YAML from 'yaml';

export const getConfig = async (path) => {
    const data = await fetch(path).then(res => res.text());

    if (path.endsWith('.yml') || path.endsWith('.yaml')) {
        return YAML.parse(data);
    } else {
        return JSON.parse(data);
    }
};

export const getWindowDimensions = ({ container: { aspect = 1.777, tolerance = 0.1 } }) => {
    const { innerWidth, innerHeight } = window;

    const realAspect = innerWidth / innerHeight;

    if(Math.abs(1-(realAspect / aspect)) > tolerance) {
        return {
            forcedLayout: true,
            real: {
                width: innerWidth,
                height: innerHeight
            },
            aspect,
            realAspect,
            ...(aspect > realAspect
                ? { width: innerWidth, height: innerWidth / aspect }
                : { width: innerHeight * aspect, height: innerHeight })
        }
    }

    return {
        forcedLayout: false,
        real: {
            width: innerWidth,
            height: innerHeight
        },
        aspect,
        realAspect,
        width: innerWidth,
        height: innerHeight
    }
};

export const getContentDimensions = ({content: {scale= 0.7, aspect= 1.7777}, container}) => {
    const { width } = getWindowDimensions({container});
    const contentWidth = (width * scale) + 1;

    return {
        width: contentWidth,
        height: contentWidth / aspect
    }
};

export const getImage = (src) => new Promise(resolve => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
        resolve(img);
    }
});

export const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
};
