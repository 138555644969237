import React from 'react';

export const ErrorDisplay = ({error}) => {
    console.log(error);
    return (
        <div style={{margin: 20}}>
            <h4>Fehler:</h4>
            <pre style={{ color: 'red' }}>
                {error.message} (code: {error.code})
                {
                    error.context &&
                    '\n\nContext:\n'+JSON.stringify(error.context, null, '  ')
                }
            </pre>
        </div>
    )
}