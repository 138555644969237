import React, {useState, useRef, useEffect} from 'react';

import Lottie from 'react-lottie';
import anime from 'animejs';

import {InteractivityController} from './state-controller';
import {getWindowDimensions, getImage, getContentDimensions} from './utils';

import ContentPopup from './components/content-popup';

const styles = {
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        opacity: 0,
        width: '100%'
    },
    actions: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 200,
        pointerEvents: 'none'
    },
    miscLink: {
        color: 'black',
        textDecoration: 'none'
    }
};

const ua = window.navigator.userAgent.toLowerCase();
const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

//create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

export const Renderer = ({config, debug}) => {
    // state
    const [mousePos, setMousePos] = useState({x: 0, y: 0});
    const [videoSrc, setVideoSrc] = useState();
    const [nextVideoSrc, setNextVideoSrc] = useState();
    const [triggers, setTriggers] = useState([]);
    const [actions, setActions] = useState([]);
    const [currentState, setCurrentState] = useState('none');
    const [icons, setIcons] = useState();
    const [popup, setPopup] = useState();
    const [preloadingActive, setPreloadingActive] = useState(false);
    const [screenToSmall, setScreenToSmall] = useState(false);

    const forceUpdate = useForceUpdate();

    // destructuring config
    const {settings} = config;

    // refs
    const windowRef = useRef();
    const canvasRef = useRef();
    const videoRef = useRef();
    const nextVideoRef = useRef();
    const nextResolveRef = useRef();
    const backgroundRef = useRef();
    const blendImageRef = useRef();
    const actionBlendRef = useRef({opacity: 0, blending: false});
    const actionsRef = useRef();
    const staticActionsRef = useRef();
    const controllerRef = useRef();
    const videoCacheRef = useRef({});
    const inactivityTimeoutRef = useRef();

    const sizeRef = useRef({
        container: getWindowDimensions(settings),
        content: getWindowDimensions(settings)
    });

    const {width, height} = sizeRef.current.container;
    const {width: contentWidth, height: contentHeight} = sizeRef.current.content;

    const {background, logo, title, footer, map, initialState} = settings;

    const infoSize = 100;
    const infoLeft = ((width - contentWidth) / 4) - (infoSize / 2);
    const infoTop = ((height - contentHeight) / 2) + (contentHeight * 0.8);

    // rendering
    const renderToCanvas = () => {
        // TODO dont know WHEN the context must be read new...
        // maybe like this https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
        const ctx = canvasRef.current.getContext('2d');

        const {width, height} = sizeRef.current.content;

        // render background, if exits
        if (nextResolveRef.current && nextResolveRef.current.playing) {
            ctx.drawImage(nextVideoRef.current, 0, 0, width, height);
        } else {
            if (backgroundRef.current) {
                ctx.drawImage(backgroundRef.current.content, 0, 0, width, height);
            }
        }

        // if blending is enabled
        if (blendImageRef.current && blendImageRef.current) {
            // canvas image
            //console.log("Alpha", blendImageRef.current.alpha);
            ctx.globalAlpha = blendImageRef.current.alpha;
            ctx.drawImage(blendImageRef.current.image, 0, 0, width, height);
            ctx.globalAlpha = 1;
        }

        // actions container
        if (actionsRef.current && actionBlendRef.current.blending) {
            //console.log("Actions Alpha", actionBlendRef.current.opacity);
            actionsRef.current.style.opacity = actionBlendRef.current.opacity;
        }

        if (staticActionsRef.current && actionBlendRef.current.blending) {
            staticActionsRef.current.style.opacity = actionBlendRef.current.opacity;
        }


        requestAnimationFrame(renderToCanvas);
    };

    // event callbacks
    const onWindowResize = () => {

        sizeRef.current = {
            container: getWindowDimensions(settings),
            content: getContentDimensions(settings)
        };

        const {width, height} = sizeRef.current.content;

        // manual update of canvas
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        // update window
        windowRef.current.style.height = `${window.innerHeight}px`;

        const {width: containerWidth, height: containerHeight} = sizeRef.current.container;

        setScreenToSmall(containerWidth < 1024 || containerHeight < 550);
        forceUpdate();
        renderToCanvas();
    };

    const onMouseMove = ({clientX, clientY}) => {
        const {width, height, x, y} = canvasRef.current.getBoundingClientRect();

        const newPos = {
            x: (clientX - x) / width,
            y: (clientY - y) / height
        };
        setMousePos(newPos);

        return newPos;
    };

    const onTouchMove = (e) => {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;
    };

    // click handling
    const dispatchClickRaycast = (event, fire = false) => {
        if (inactivityTimeoutRef.current && fire === true) {
            console.log("Inactivity timeout running, clearing");
            clearTimeout(inactivityTimeoutRef.current);
            inactivityTimeoutRef.current = undefined;
        }

        // dispatch event
        const currentMouse = onMouseMove(event);

        // mark all items to check
        const itemsToScan = [
            {type: 'trigger', data: triggers},
            {type: 'action', data: actions}
        ];

        // filter all in Range
        const candidates = itemsToScan.reduce((stack, items) => {
            items.data
                .filter(item => icons && item.icon && item.pos && icons[item.icon.name]) // icon must exist
                .map(item => {
                    const icon = icons[item.icon.name];
                    // default size to icon, if not overwritten by icon
                    const {clickableSize = icon.clickableSize || item.icon.size || icon.size} = item.icon;

                    const relativeSize = clickableSize;

                    const diff = {
                        x: item.pos.x - currentMouse.x,
                        y: item.pos.y - currentMouse.y
                    };

                    return {
                        ...item,
                        relativeSize,
                        dist: Math.sqrt((diff.x * diff.x) + (diff.y * diff.y))
                    }
                })
                .filter(item => {
                    return item.dist < (item.relativeSize / 2);
                })
                .forEach(item => stack.push({...item, kind: items.type}));

            return stack;
        }, []);


        // get minimum distance
        if (candidates.length > 0) {
            const nearest = candidates.reduce((min, item) => {
                if (min.dist > item.dist) {
                    min.item = item;
                    min.dist = item.dist;
                }
                return min;
            }, {dist: Number.MAX_VALUE, item: null});

            if (fire) {
                switch (nearest.item.kind) {
                    case 'trigger':
                        // trigger state change, transition
                        setPopup(null);
                        controllerRef.current.machine[nearest.item.to]();
                        break;
                    case 'action':
                        if (nearest.item.type === 'popup') {
                            setPopup(nearest.item);
                            break;
                        }
                    default:
                        console.log(`Clicking Item of type ${nearest.item.type} is not supported`);
                }
            }
        }
    };

    useEffect(() => {
        console.log('Setting up Renderer...');
        setIcons(null);

        // preload all icons for speed
        Promise.all(Object.keys(config.icons).map(async (iconKey) => {
            const {[iconKey]: icon} = config.icons;

            switch (icon.type) {
                case 'json':
                    return {
                        key: iconKey,
                        ...icon,
                        data: await fetch(`${settings.base}/${icon.name}.json`).then(res => res.json())
                    };
                default:
                    console.log(`Icon "${icon.type}" is not implemented`);
            }
        })).then((icons) => {
            setIcons(icons.reduce((prev, icon) => {
                prev[icon.key] = icon;
                return prev;
            }, {}));

            if(!screenToSmall) {
                setPopup({
                    content: {
                        name: 'content/info',
                        title: 'Willkommen auf der interaktiven Landkarte der Nachhaltigkeit!',
                        pos: {
                            x: 0.25,
                            y: 0.25,
                            width: 0.5,
                            height: 0.5
                        }
                    }
                })
            }
        });

        // setup controller
        const controller = new InteractivityController(config, {
            setState: async (key, {background, blends, triggers, actions}) => {
                if (!background) {
                    console.warn("Background not set for state:", key, background);
                    return;
                }

                const {type, name} = background;
                switch (type) {
                    case 'png':
                    case 'jpg':
                        const img = await getImage(`${config.settings.base}/${name}.${type}`);
                        backgroundRef.current = {
                            content: img,
                            type
                        };
                        break;
                    case 'mp4':
                        const src = `${config.settings.base}/${name}.mp4`;
                        setVideoSrc(src);
                        await new Promise((resolve, reject) => {
                            backgroundRef.current = {
                                content: videoRef.current,
                                type
                            };
                            if (videoCacheRef.current[src]) {
                                resolve();
                            } else {
                                backgroundRef.current.resolve = resolve;
                                videoCacheRef.current[src] = true;
                            }
                        });
                        break;
                    default:
                        console.warn("Set State: Unknown media type", type, name);
                }

                setTriggers(triggers || []);
                setActions(actions || []);
                renderToCanvas();
            },
            preloadTransitionVideo: async (key, state, trigger) => {
                return new Promise(resolve => {
                    setPreloadingActive(trigger.to);

                    nextResolveRef.current = {
                        resolve
                    };
                    setNextVideoSrc(`${config.settings.base}/${trigger.animation}.mp4`);
                    nextVideoRef.current.playbackRate = 4;
                }).then(res => {
                    console.log("Video Preloaded!");
                    setPreloadingActive(false);
                });
            },
            blendActions: async (direction) => {
                actionBlendRef.current.blending = true;
                const actionBlendInstance = anime({
                    targets: actionBlendRef.current,
                    opacity: direction ? 1 : 0,
                    duration: 350,
                    easing: 'easeInOutCubic'
                });
                actionBlendInstance.play();
                await actionBlendInstance.finished;
                actionBlendRef.current.blending = false;
            },
            blendImage: async (path, start = 0, end = 1) => {
                const image = await getImage(`${config.settings.base}/${path}`);
                console.log("Start Blend");
                blendImageRef.current = {
                    image,
                    alpha: start
                };
                const blendInstance = anime({
                    targets: blendImageRef.current,
                    alpha: end,
                    duration: 500,
                    easing: 'easeInOutCubic'
                });
                blendInstance.play();
                await blendInstance.finished;
                console.log("End Blend");
                blendImageRef.current = null;
            },
            transitionVideo: async (key, state) => {
                nextVideoRef.current.currentTime = 0;

                await new Promise(resolve => {
                    nextResolveRef.current = {
                        resolve,
                        playing: true
                    };
                    nextVideoRef.current.play();
                });
            },
            updateState: (state) => {
                setCurrentState(state);

                if (state !== settings.initialState) {
                    console.log("State non initialState, starting inactivity Timeout", state);

                    inactivityTimeoutRef.current = setTimeout(() => {
                        console.log("Going back to idle due to inactivity");
                        inactivityTimeoutRef.current = undefined;
                        controller.machine.idle();
                        setTimeout(() => {
                            setPopup({
                                content: {
                                    name: 'content/info',
                                    title: 'Willkommen auf der interaktiven Landkarte der Nachhaltigkeit!',
                                    pos: {
                                        x: 0.25,
                                        y: 0.25,
                                        width: 0.5,
                                        height: 0.5
                                    }
                                }
                            });
                        }, 2000);
                    }, settings.inactivityTimeout * 1000);
                }

            }
        });

        controllerRef.current = controller;

        // events
        window.addEventListener('resize', onWindowResize);
        document.addEventListener('touchmove', onTouchMove);
        window.addEventListener('mousemove', dispatchClickRaycast);

        // hit first render
        onWindowResize();

        // cleanup after we are done
        return () => {
            // remove all eventlisteners
            window.removeEventListener('resize', onWindowResize);
            window.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('touchmove', onTouchMove);

            // destroy controller
            controller.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, debug]);

    console.log(screenToSmall);

    return (
        <div
            ref={windowRef}
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0
            }}>
            <div style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundImage: `url(${settings.base}/${background.type !== 'mp4' ? `${background.name}.${background.type}` : ''})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                filter: `saturate(${background.saturation})`
            }}>
            </div>
            <div style={{
                position: 'absolute',
                left: 20,
                bottom: 20,
                zIndex: 1000,
                fontSize: '1em'
            }}>
                <a style={styles.miscLink} href={settings.misc.privacyPolicy}>Datenschutz</a> | <a
                style={styles.miscLink} href={settings.misc.imprint}>Impressum</a>
            </div>
            {
                screenToSmall &&
                <div style={{position: 'absolute', left: 10, top: 10, right: 10, width:'100%'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div style={{
                            backgroundImage: `url(${settings.base}/${logo.name})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            width: `100px`,
                            height: `100px`,
                            marginRight: '20px'
                        }}/>
                    </div>
                    <div style={{position: 'relative'}}>
                        <ContentPopup isOpen={screenToSmall} useRealSize={true} icons={icons} config={config} popup={{
                            hideClose: true,
                            content: {
                                name: 'content/mobile',
                                title: 'Bildschirm zu klein',
                                width: 0.9,
                                height: 0.9,
                                pos: {
                                    x: 0.05,
                                    y: 0.05
                                }
                            }
                        }}/>
                    </div>
                </div>
            }
            {
            <div style={{
                position: 'relative',
                width: `${width}px`,
                height: `${height}px`,
                margin: 'auto',
                border: 'solid 1px rgba(0,0,0, 0.2)',
                visibility: screenToSmall ? 'hidden': 'visible'
            }}>

                <div style={{
                    position: 'absolute',
                    left: `${infoLeft}px`,
                    top: `${infoTop}px`,
                    width: infoSize,
                    height: infoSize,
                    cursor: 'pointer',
                    zIndex: 1000,
                    visibility: screenToSmall ? 'hidden': 'visible'
                }}
                     onClick={() => {
                         setPopup({
                             content: {
                                 name: 'content/info',
                                 title: 'Willkommen auf der interaktiven Landkarte der Nachhaltigkeit!',
                                 pos: {
                                     x: 0.25,
                                     y: 0.25,
                                 },
                                 width: 0.5,
                                 height: 0.5
                             }
                         })
                     }}
                >
                    {
                        icons &&
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: icons.info.data,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }
                            }}
                            width={100}
                            height={100}
                        />
                    }
                </div>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    fontSize: '2em',
                    fontWeight: 500
                }}>
                    <div style={{display: 'flex', padding: `${title.padding}px`}}>
                        <div>
                            {title.text}
                        </div>
                        <div style={{
                            flex: 1,
                            borderBottom: `black dashed 2px`,
                            lineHeight: `12px`,
                            marginBottom: `8px`,
                            marginLeft: `10px`,
                        }}>
                        </div>
                    </div>
                </div>
                <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%', visibility: screenToSmall ? 'hidden': 'visible'}}>
                    <div style={{display: 'flex', position: 'relative', justifyContent: 'center'}}>
                        <div style={{
                            position: 'relative',
                            width: `${footer.size * 100}%`,
                            height: `${(height - contentHeight) / 2}px`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div style={{

                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                borderRadius: '10px',
                                padding: 10,
                                width: '100%',
                            }}>
                                <img
                                    width={`${width * footer.size}px`}
                                    src={`${settings.base}/${footer.name}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{position: 'absolute', bottom: 40, right: 80, zIndex: 100, visibility: screenToSmall ? 'hidden': 'visible'}}>
                    <div style={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: `rgba(0,0,0, ${map.opacity})`,
                        padding: width * map.size * map.padding,
                        borderRadius: `${map.border}px`
                    }}>
                        <div style={{
                            backgroundImage: `url(${settings.base}/${map.name})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            width: `${width * map.size * map.aspect}px`,
                            height: `${width * map.size}px`
                        }}>

                        </div>
                    </div>
                </div>
                {icons && !screenToSmall &&
                <ContentPopup isOpen={!!popup} icons={icons} config={config} popup={popup}
                              onClose={() => setPopup(null)}/>}
                <div style={{
                    width: `${contentWidth}px`,
                    height: `${contentHeight}px`,
                    position: 'relative',
                    margin: 'auto',
                    marginTop: `${(height - contentHeight) / 2}px`,
                    visibility: screenToSmall ? 'hidden': 'visible'
                }}
                     onClick={(event) => {
                         dispatchClickRaycast(event, true)
                     }}
                >
                    {
                        debug && <div style={{position: 'fixed', bottom: 0, left: 0, zIndex: 1000}}>
                            <div
                                style={{
                                    position: 'relative',
                                    margin: 12,
                                    padding: 8,
                                    backgroundColor: 'rgba(0,0,0, 0.2)'
                                }}>
                                <div><b>Debug:</b></div>
                                <pre>Relative Mouse: x:{mousePos.x.toFixed(3)} y:{mousePos.y.toFixed(3)}</pre>
                                <pre>
                                Props:
                                    {JSON.stringify({
                                        video: videoRef.current && videoRef.current.src,
                                        transitionVideo: nextVideoRef.current && nextVideoRef.current.src,
                                        background: backgroundRef.current && backgroundRef.current.content.src,
                                        blending: nextResolveRef.current && nextResolveRef.current.playing
                                    }, null, '\t')}
                            </pre>
                            </div>
                        </div>
                    }
                    <div style={{
                        backgroundImage: `url(${settings.base}/${logo.name})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        right: `${logo.position * contentWidth}px`,
                        top: `${-logo.size / 2}px`,
                        width: `${logo.size}px`,
                        height: `${logo.size}px`,
                        position: 'absolute'
                    }}>
                    </div>
                    <div
                        ref={staticActionsRef}
                        style={{
                            position: 'absolute',
                            left: 20,
                            top: 20,
                            zIndex: 300
                        }}
                    >
                        {
                            currentState !== 'none' && currentState !== initialState &&
                            <div style={{cursor: 'pointer'}} onClick={() => {
                                setPopup(null);
                                controllerRef.current.machine.idle()
                            }}>
                                {
                                    icons &&
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: preloadingActive ? icons.back_aktiv.data : icons.back.data,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        width={100}
                                        height={100}
                                    />
                                }
                            </div>
                        }
                    </div>
                    <canvas
                        style={{zIndex: 100, border: `white solid ${settings.content.border}px`}}
                        ref={canvasRef}
                    >
                    </canvas>
                    <div ref={actionsRef} style={styles.actions}>
                        {
                            icons && [...triggers, ...actions].filter(t => t.pos && icons[t.icon.name]).map((trigger, i) => {
                                const iconKey = (preloadingActive === trigger.to && trigger.icon.active && icons[trigger.icon.active])
                                    ? trigger.icon.active
                                    : trigger.icon.name;

                                //console.log("Icon Key", iconKey, trigger);

                                const icon = icons[iconKey];
                                const {size = icon.size} = trigger.icon;
                                const realSize = contentWidth * size;
                                return (<div key={i}
                                             style={{
                                                 position: 'absolute',
                                                 left: (contentWidth * trigger.pos.x) - (realSize / 2),
                                                 top: (contentHeight * trigger.pos.y) - (realSize / 2),
                                             }}>
                                    <div style={{position: 'relative'}}>
                                        {
                                            icon.type === 'json' &&
                                            <Lottie
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: icon.data,
                                                    rendererSettings: {
                                                        preserveAspectRatio: 'xMidYMid slice'
                                                    }
                                                }}
                                                width={realSize}
                                                height={realSize}
                                            />
                                        }
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                    <video
                        style={styles.video}
                        src={videoSrc}
                        autoPlay={true}
                        loop={true}
                        onLoadedData={() => {
                            console.log("Foreground loaded data");
                            if (backgroundRef.current && backgroundRef.current.resolve) {
                                backgroundRef.current.resolve();
                                delete backgroundRef.current.resolve;
                            }
                        }}
                        ref={videoRef}>
                    </video>
                    <video
                        style={styles.video}
                        src={nextVideoSrc}
                        autoPlay={isiOS}
                        onLoadedData={() => {
                            console.log("Background loaded data");
                            if (nextResolveRef.current && nextResolveRef.current.resolve) {
                                nextResolveRef.current.resolve();
                                nextResolveRef.current = null;
                            }
                        }}
                        onEnded={() => {
                            if (nextResolveRef.current && nextResolveRef.current.resolve) {
                                nextResolveRef.current.resolve();
                                nextResolveRef.current = null;
                            }
                        }}
                        ref={nextVideoRef}>
                    </video>
                </div>
            </div>
            }
        </div>
    );
};